import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from 'src/Globals';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private httpClient: HttpClient, private global: Globals) { }

    loginUsuario(usuario: string, password: string, application: string) {
        const body = 'email=' + usuario + '&password=' + password + '&application=' + application + "&tipo_perfil=P";

        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        );

        return this.httpClient.post(environment.API_ENDPOINT_ES + '/auth/login', body, { headers });
    }

    logoutUsuario(token: string) {
        const headers = new HttpHeaders(
            {
                Accept: 'application/json'
            });
        const url = `${environment.API_ENDPOINT_ES}/auth/logout`;
        return this.httpClient.post(url, null, { headers });
    }

    refrescarPermisos() {
        const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/misPermisos`;
        return this.httpClient.get(url, { headers });
    }

    parametrosPorDefecto() {
        const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/parametrosDefault`;
        return this.httpClient.get(url, { headers });
    }

    refreshToken(token: string) {
        console.log("Refresh token en servicio de refrsh token: ", token);

        const body = 'refresh_token=' + token;
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
                Authorization: 'Bearer ' + token
            }
        );

        return this.httpClient.post(`${environment.API_ENDPOINT_ES}/auth/refreshToken`, body, { headers });
    }

    recuperarPassword(form: FormData) {
        const headers = new HttpHeaders(
            { 'Content-Type': 'application/json' }
        );
        const url = `${environment.API_ENDPOINT_ES}/passwords`;
        return this.httpClient.put(url, JSON.stringify(form), { headers });
    }

    getParametrosLogin() {
        const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/parametrosLogin`;
        return this.httpClient.get<any>(url, { headers });
    }

    enviarSolicitudCambioContrasenha(jsonData: string) {
        const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/passwords`;
        return this.httpClient.post(url, jsonData, { headers });
    }

    // Este servicio SOLICITA el cambio de contrasenha
    solicitarCambioContrasenha(body: any) {
        // const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/password_reset`;
        return this.httpClient.post(url, body);
    }

    // Este servicio CAMBIA la contrasenha
    cambiarContrasenha(body: { password: string, password_confirmation: string, email: string }, token: string) {
        // const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/password_reset/${token}`;
        return this.httpClient.post(url, body);
    }

    registrarUsuario(jsonData: string) {
        const headers = this.global.getHeader();
        const url = `${environment.API_ENDPOINT_ES}/register`;
        return this.httpClient.post(url, jsonData, { headers })
    }

    changePassword(password: string, token: string) {
        const headers = new HttpHeaders(
            {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            });
        const url = `${environment.API_ENDPOINT_ES}/usuarios/changePasswords`;
        return this.httpClient.put(url, password, { headers });
    }

}