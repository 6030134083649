import { Injectable } from '@angular/core';
import { IParametrosData } from '../../interfaces/parametros/IParametros';
import { User } from '../../interfaces/user/IUser';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  USER_KEY = 'user';
  TOKEN_KEY = 'token';
  REFRESH_TOKEN_KEY = 'refresh_token';

  constructor() {
  }

  public storeOnLocalStorage(user: any): void {
    if (user !== null) {
      user.password = '';
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
      localStorage.setItem(this.TOKEN_KEY, user.userData[0].token);
      localStorage.setItem(this.REFRESH_TOKEN_KEY, user.userData[0].refresh_token);
    }
  }

  public setToken(newToken: string): void {
    localStorage.setItem(this.TOKEN_KEY, newToken);
  }

  public setRefreshToken(newRefreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, newRefreshToken);
  }

  public getUserFromStorage(): any {
    return JSON.parse(<string>localStorage.getItem(this.USER_KEY));
  }

  public cleanStorage(): void {
    let controlExistencia = localStorage.getItem('control_existencia');
    localStorage.clear();
    if (controlExistencia) {
      console.log('Este es el control de existencia: ', controlExistencia);
      this.storeData('control_existencia', controlExistencia);
    }
  }

  public getTokenFromStorage(): string {
    const user = localStorage.getItem('user');
    if (!user) {
      return ''
    }
    const userParsed = user ? JSON.parse(user) : null;
    return userParsed.userData[0].token;
  }

  public getRefreshTokenFromStorage(): string {
    return <string>localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  public storeParametros(parametros: any[]) {
    localStorage.setItem('parametros', JSON.stringify(parametros));
  }

  public getParametrosFromStorage(): IParametrosData {
    return JSON.parse(<string>localStorage.getItem('parametros'));
  }

  public removeToken(): void {
    localStorage.clear();
  }

  public storeSearch(search: any, searchFrom: string): void {
    this.clearStoreSearch();
    localStorage.setItem('search_from', searchFrom);
    localStorage.setItem('search', JSON.stringify(search.value));
  }

  public getStoreSearchFrom(): any {
    return localStorage.getItem('search_from');
  }

  public getStoreSearch(): any {
    return JSON.parse(<string>localStorage.getItem('search'));
  }

  public clearStoreSearch(): void {
    localStorage.removeItem('search');
  }

  public get authenticated(): boolean {
    // const expiration = parseInt(localStorage.getItem('expiration'), 10);
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('user')) as User;
    // if (!(expiration && expiration > 0)) return false;
    // @ts-ignore
    if (!(user?.userData.expires_in)) return false;
    // tslint:disable-next-line:variable-name
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    var t = new Date();
    t.setSeconds(user.userData.expires_in);
    return !(new Date() > t);
  }

  public storeData(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  public getStoredData(name: string): any {
    return localStorage.getItem(name);
  }
}