import { Injectable } from '@angular/core';
import { environment } from './environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class Globals {

    constructor() { }

    getHeader() {
        return new HttpHeaders(
            {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            });
    }

    makeUrl(nombreServicio: string, search?: any | undefined, pagination?: any | undefined): string {
        let url: string = `${environment.API_ENDPOINT_ES}${nombreServicio}`;
        const params = [];

        for (const prop in search) {
            if (search[prop] !== undefined && search[prop] !== null && search[prop] !== '') {
                params.push(prop + '=' + search[prop]);
            }
        }

        for (const prop in pagination) {
            if (pagination[prop] !== undefined) {
                params.push(prop + '=' + pagination[prop]);
            }
        }

        if (params.length) {
            url += '?' + params.join('&');
        }

        return url;
    }

    getSystemVersion(): string { return environment.system_version.version; }

}
