import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface ErrorResponse {
  success: boolean;
  data: string[];
  errors: string[];
  metadata: any;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private toastr: ToastrService) { }

  handleError(error: any) {
    // this.toastr.clear();
    if (error?.status === 409 || error?.status === 422 || error?.status === 500) {
      const mensaje: ErrorResponse = error.error;
      if (error.error.data) {
        if (typeof error.error.data === 'string') {
          this.toastr.error(error.error.data, 'Atención');
        } else {
          if (error.error.data.length) {
            this.toastr.error(error.error.data.at(0).message ?? 'Ocurrió un error', 'Atención');
          } else {
            this.toastr.error('Ocurrió un error inesperado', 'Atención');
          }
        }
      }
      if (mensaje.errors && mensaje.errors.length >= 0) {
        mensaje.errors.forEach((mensajeError: any) => {
          this.toastr.error(mensajeError, 'Atención');
        });
      } else {
        if (mensaje.message) {
          this.toastr.error(mensaje.message, 'Atención');
        } else {
          // this.toastr.error('Respuesta de servidor no esperada. Contacte con el administrador', 'Atención',
          // );
        }
      }
    } else if (error?.status === 400) {
      this.toastr.warning('La sesión expiró, por favor, ingrese de nuevo', 'Atención');
    } else if (error?.status === 403) {
      this.toastr.info('Acción bloqueada. El usuario logueado no tiene permisos para realizar lo solicitado, contacte con el administrador.', 'Atención');
    }
    else {
      //this.toastr.error('Se produjo un error en el sistema. Revise los mensajes de alerta o contacte con el administrador', 'Atención');
    }
  }
}
