import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../../shared/services/local-storage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../shared/services/error-service/error.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private injector: Injector,
    private tokenManager: AuthService,
    private toastr: ToastrService,
    private localStorage: LocalStorageService,
    private errorHandler: ErrorService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (!this.needsToken(request)) {
      return next.handle(request);
    }
    const token = this.localStorage.getTokenFromStorage();
    if (!token) {
      return next.handle(request);
    }
    const tokenizedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });

    return next.handle(tokenizedRequest)
      .pipe(
        catchError((error: any) => {
          if (error.status === 401) {
            /* Se verifica si el Token ha vencido */
            return this.handle401Error(tokenizedRequest, next);
          } else {
            if (error.status === 422) {
              this.errorHandler.handleError(error);
            }
            if (error.status === 400) {
              /* En caso de que el REFRESH TOKEN haya vencido, le envía al login. Si no es el caso, maneja el error normalmente */
              return this.handle400Error(error, tokenizedRequest.url);
            } else {
              /* Si es un error cualquiera viene por aquí*/
              return throwError(() => { console.log("Manejando el error desde COMPONENTE") });
            }
          }
        }),
      );
  }

  public needsToken(req: HttpRequest<unknown>): boolean {
    return !req.url.includes('login') ||
      !req.url.includes('parametrosLogin') ||
      !req.url.includes('passwords')
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.toastr.error('La sesión expiró. Por favor, inicie sesión nuevamente');
    this.tokenManager.goToLogin();
    /* Se agrega el método distinctUntilChanged para evitar que haya iteraciones excesivas en los switchMap */
    if (this.isRefreshingToken) {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        distinctUntilChanged(),
        switchMap(token => {
          console.log("Token linea 78: ", token);
          return next.handle(this.addNewAccessTokenToHeaders(request, token));
        }));
    } else {
      console.log('En el else del 401')
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      return this.tokenManager.renewAccessToken().pipe(
        distinctUntilChanged(),
        switchMap((token: any) => {
          this.isRefreshingToken = false;
          this.tokenSubject.next(token.data.token);
          return next.handle(this.addNewAccessTokenToHeaders(request, token.data.token, token.data.refresh_token));
        })
      );
    }
  }

  addNewAccessTokenToHeaders(req: HttpRequest<any>, token: string, newRefreshToken: string = ''): HttpRequest<any> {
    req = req.clone({});
    this.injector.get(AuthService).setToken(token);
    if (newRefreshToken !== '') {
      this.injector.get(AuthService).setRefreshToken(newRefreshToken);
    }
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  handle400Error(error: HttpErrorResponse, requesUrl: any) {
    if (error.status === 400 && requesUrl.includes('refreshToken')) {
      this.isRefreshingToken = false;
      this.tokenManager.goToLogin();
    }
    return throwError(() => console.error(error))
  }

}